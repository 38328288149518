
import { defineComponent, onMounted, onUpdated } from 'vue';
import { useI18n } from 'vue-i18n';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import { ToggleComponent } from '@/assets/ts/components/_ToggleComponent';
import KTMenu from '@/layout/aside/Menu.vue';
import { asideTheme } from '@/core/helpers/config';

export default defineComponent({
  name: 'KTAside',
  components: {
    KTMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      DrawerComponent.reinitialization();
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
      t,
    };
  },
});
