
import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { mapActions, useStore } from 'vuex';
import { Actions } from '@/store/enums/AuthEnums';
import { get, join } from 'lodash';
import Avatar from '@/components/avatars/Index.vue';
import { User, Roles } from '@/models/UserModel';

export default defineComponent({
  name: 'kt-user-menu',
  components: {
    Avatar,
  },
  props: {
    currentUser: {
      type: Object,
      default: () => {
        return {} as User;
      },
    },
    image: {
      type: String,
    },
  },
  computed: {
    userEmail() {
      if (typeof this.currentUser !== 'object') return '';

      return this.currentUser.email ? this.currentUser.email : '';
    },
    userName() {
      if (typeof this.currentUser !== 'object') return '';

      if (this.currentUser.first_name && this.currentUser.last_name) {
        return `${this.currentUser.first_name} ${this.currentUser.last_name}`;
      }

      return this.currentUser.email;
    },
    userRoles() {
      if (typeof this.currentUser !== 'object') return '';

      if (typeof this.currentUser.roles === 'undefined') return '';

      return this.currentUser.roles ? this.currentUser.roles[0] : '';
    },
    userSubscription() {
      if (typeof this.currentUser !== 'object') return '';

      if (typeof this.currentUser.subscription === 'undefined') return '';

      if (this.userRoles === Roles.ADMIN) return '';

      return this.currentUser.subscription !== null
        ? this.currentUser.subscription.code
        : 'basic';
    },
    mailTo() {
      if (typeof this.currentUser === 'undefined') return '';

      return `mailto:${this.currentUser.email}`;
    },
  },
  methods: {
    ...mapActions({
      signOut: Actions.LOGOUT,
    }),
  },
  setup() {
    const i18n = useI18n();
    const store = useStore();

    i18n.locale.value = localStorage.getItem('lang')
      ? (localStorage.getItem('lang') as string)
      : 'en';

    const countries = {
      en: {
        flag: '/media/flags/united-states.svg',
        name: 'English',
      },
      es: {
        flag: '/media/flags/spain.svg',
        name: 'Spanish',
      },
      de: {
        flag: '/media/flags/germany.svg',
        name: 'German',
      },
      ja: {
        flag: '/media/flags/japan.svg',
        name: 'Japanese',
      },
      fr: {
        flag: '/media/flags/france.svg',
        name: 'French',
      },
    };

    const setLang = (lang) => {
      localStorage.setItem('lang', lang);
      i18n.locale.value = lang;
    };

    const currentLanguage = (lang) => {
      return i18n.locale.value === lang;
    };

    const currentLangugeLocale = computed(() => {
      return countries[i18n.locale.value];
    });

    const user = computed(() => {
      return store.getters.authUser;
    });

    const myRoles = computed(() => {
      return join(get(user, 'value.roles', []), ', ');
    });

    const hasThisRole = (role: string): boolean => {
      return get(user, 'value.roles', []).includes(role);
    };

    return {
      user,
      myRoles,
      setLang,
      countries,
      hasThisRole,
      currentLanguage,
      currentLangugeLocale,
    };
  },
});
