
import { defineComponent } from 'vue';
import KTUserMenu from '@/views/new-design/layout/Header/UserMenu.vue';
import Avatar from '@/components/avatars/Avatar.vue';
import { mapActions, mapGetters } from 'vuex';
import { Actions, Getters } from '@/store/enums/AuthEnums';
import { acronym } from '@/utils/text';

export default defineComponent({
  name: 'topbar',
  components: {
    Avatar,
    KTUserMenu,
  },
  props: {
    currentUser: {
      type: Object,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    ...mapGetters({
      profilePicture: Getters.GET_AUTH_USER_PROFILE_PICTURE,
      actionErrors: Getters.GET_AUTH_USER_ACTION_ERROR,
    }),
    acronym() {
      return acronym(
        `${this.currentUser?.first_name} ${this.currentUser?.last_name}`
      );
    },
  },
  mounted() {
    document.addEventListener('click', this.close);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.close);
  },
  methods: {
    // ...mapActions({
    //   getProfilePicture: Actions.PULL_USER_PROFILE_PICTURE,
    // }),

    // async handleFetchProfile() {
    //   return await this.getProfilePicture().catch(async () => {
    //     const { errors, messages } = this.actionErrors;
    //     console.log('errors handleFetchProfile', { errors, messages });
    //   });
    // },
    close(e) {
      let el = this.$refs.userMenu;
      let target = e.target;
      if (el !== target && !(el as any).contains(target)) {
        this.showMenu = false;
      }
    },
  },
});
