const DocMenuConfig = [
  {
    pages: [
      {
        heading: 'dashboard',
        route: '/dashboard',
        svgIcon: '/media/icons/duotone/Home/Home2.svg',
        fontIcon: 'bi-app-indicator',
      },
      {
        heading: 'Get Tenant Reference',
        route: '/tenant-report/search',
        svgIcon: '/media/icons/duotone/General/Search.svg',
        fontIcon: 'bi-app-indicator',
      },
      {
        heading: 'Submit Tenant Reference',
        route: '/tenant-report/submit',
        svgIcon: '/media/icons/duotone/General/Clipboard.svg',
        fontIcon: 'bi-app-indicator',
      },
      {
        heading: 'Request Tenant Reference',
        route: '/tenant-report/request',
        svgIcon: '/media/icons/duotone/Interface/Envelope.svg',
        fontIcon: 'bi-app-indicator',
      },
    ],
  },
];

export default DocMenuConfig;
