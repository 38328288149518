
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'alert-invitation',
  methods: {
    handleGoto(name) {
      this.$router.push({ name: name, query: {} });
    },
    handleSkip() {
      this.$router.push({ name: 'dashboard', query: {} });
    },
  },
});
