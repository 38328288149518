
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'upload-profile-modal',
  data: () => ({
    modal: {} as HTMLElement,
    editMode: false,
    file: '',
    imageDisplay: '',
  }),
  props: {
    image: {
      type: String,
      default: '',
    },
  },
  emits: ['changeProfileForm', 'removeProfileAvatar'],
  mounted() {
    // setup the modal reference and event listener
    this.modal = document.getElementById(
      't2o_profile_form_modal'
    ) as HTMLElement;

    if (this.modal) {
      this.modal.addEventListener('hidden.bs.modal', () => {
        this.imageDisplay = this.image as any;
        this.editMode = false;
      });
    }
  },
  methods: {
    onFileHandle(e) {
      this.editMode = true;
      this.file = e.target.files[0] ?? '';
      this.imageDisplay = URL.createObjectURL(e.target.files[0]);
    },
    remove() {
      // this.imageDisplay = '';
      this.editMode = false;
      this.$emit('removeProfileAvatar');
    },
    save() {
      this.editMode = false;
      this.$emit('changeProfileForm', this.file);
    },
  },
  watch: {
    image(values) {
      if (typeof values !== 'undefined') {
        this.imageDisplay = values;
      }
    },
  },
});
