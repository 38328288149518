
import { defineComponent } from 'vue';
import UploadProfile from '@/components/modals/forms/UploadProfile.vue';

export default defineComponent({
  props: {
    size: {
      type: Number,
    },
    useIcons: {
      type: Boolean,
      default: () => false,
    },
    image: {
      type: String,
    },
  },
  components: {
    UploadProfile,
  },
  data: () => ({
    isEdit: false,
    avatarLetter: '',
    avatarPicture: '',
    displayImage: '',
  }),
  emits: ['changeProfilePicture', 'removeProfilePicture'],
  methods: {
    handleSave(values) {
      this.$emit('changeProfilePicture', values);
    },
    handleRemove() {
      this.$emit('removeProfilePicture');
    },
  },
  mounted() {
    this.displayImage = this.image as any;
  },
  watch: {
    image(values) {
      this.displayImage = values;
    },
  },
});
